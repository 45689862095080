import React, { useContext, useEffect } from "react";
import { Context } from "redux/Store";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA } from "common/callouts";

import frontImage from "images/face/faceproducts/gel-cream.webp";
import frontCapImage from "images/face/faceproducts/gel-cream-capoff.webp";
import backImage from "images/face/faceproducts/gel-cream-back.webp";
import frontZoomImage from "images/face/faceproducts/gel-cream.webp";
import frontCapZoomImage from "images/face/faceproducts/gel-cream-capoff.webp";
import backZoomImage from "images/face/faceproducts/gel-cream-back.webp";

import img2 from "images/face/faceproducts/gel-cream-hydration-chart.webp";

import img6 from "images/callouts/eucerin-face-product-family.webp";
import img7 from "images/callouts/cout-footer-daily-skin.webp";
//import img8 from "images/callouts/cout-footer-facecare.webp";
import img8 from "images/callouts/sunproducts2x.webp";
import "./gelcream.scss";

function EucerinFaceImmersiveHydrationNightCreamPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    setTimeout(() => {
      const myHash = window.location.hash.slice(1);
      const myScrollSpot = document.getElementById(myHash);
      if (myScrollSpot) {
        const currentBlock = myScrollSpot.getBoundingClientRect();
        window.scrollTo(0, currentBlock.top - 150);
      }
    }, 500);
  }, []);

  return (
    <Layout
      pageClass="page-face-gelcream page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Face Immersive Hydration Gel-Cream"
        pageDescription=""
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#106E9E"
              categoryName="FACE CARE"
              categoryFontColor="#ffffff"
              productName="Eucerin<sup>®</sup> Face Immersive Hydration <nobr>Gel-Cream</nobr>"
              productSlider={[frontImage, frontCapImage, backImage]}
              zoomImages={[frontZoomImage, frontCapZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold oceanicblue-text sub-title">
                  Enhance the routine with 72-hour hydration
                </p>
                <p>
                  <span className="font-semi-bold">
                    Eucerin Face Immersive Hydration <nobr>Gel-Cream</nobr>
                  </span>{" "}
                  is designed as an alternative night cream that helps restore
                  the skin’s moisture barrier and leaves skin visibly smoother
                  and more radiant.
                </p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>
                      Diminishes the appearance of fine lines and wrinkles
                    </span>
                  </li>
                  <li>
                    <span>Ultra-lightweight formulation</span>
                  </li>
                  <li>
                    <span>
                      Apply in the evening as an alternative to Eucerin
                      <sup>®</sup> Face Immersive Hydration Night Cream
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold oceanicblue-text sub-title">
                  Key ingredients
                </p>
                <p className="no-mb font-semi-bold">
                  Multi-weight hyaluronic acid
                </p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>
                      Intense moisture hydrates deep within the surface layers
                      of the skin
                    </span>
                  </li>
                </ul>
                <p className="no-mb font-semi-bold">Antioxidant complex</p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>
                      <span className="no-mb font-semi-bold">
                        Soy-Derived Glycine-Saponin
                      </span>{" "}
                      is an effective, natural substance that helps scavenge
                      free radicals
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="no-mb font-semi-bold">
                        Vitamin E (Tocopherol)
                      </span>{" "}
                      helps guard against free radical formation
                    </span>
                  </li>
                </ul>
                <p className="no-mb font-semi-bold">
                  Panthenol (Provitamin B5)
                </p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>Softens and conditions skin</span>
                  </li>
                  <li>
                    <span>Binds water to support moisture</span>
                  </li>
                  <li>
                    <span>Supports a visibly renewed appearance</span>
                  </li>
                </ul>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold oceanicblue-text sub-title">
                    Formulated for all skin types
                  </p>
                  <ul className="checkmarks oceanicblue">
                    <li>
                      <span>NON-COMEDOGENIC</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </section>
        </div>

        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="CLINICAL GRADING"
              productThemeColor="oceanic-blue"
              id="clinicalgrading"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Clinical Grading of significant improvement in hydration beginning at week 2 when using Eucerin Face gel cream"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Eight-week
                    study to compare moisturization effects and changes in
                    facial skin quality following application of Immersive
                    Hydration Gel-Cream. Females (n=35), aged 25 to 65, with
                    mild to moderate facial dryness and visible fine lines and
                    wrinkles, applied product to the face once per day in the
                    morning. Clinical grading on face (dryness, roughness, fine
                    lines, and wrinkles) assessed at baseline, Week 2, Week 4,
                    and Week 8.
                  </p>
                  <p className="references-symbols last">
                    *Statistically significant compared to baseline (
                    <span className="font-italic">p&lt;</span>0.05).
                  </p>
                  <p className="references last">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Significant&nbsp;
                    <br className="show-desktop" />
                    improvements <br className="show-desktop" />
                    begin at Week 2<sup>1</sup>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Resources for Face Care"
                  caption="Find resources to support your face care recommendations"
                  buttonText="BROWSE RESOURCES"
                  buttonUrl="/resources"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img7}
                  imgAlt="EXPLORE BODY CARE"
                  caption="Clinically proven therapeutic solutions for daily skin care"
                  buttonText="EXPLORE BODY CARE"
                  buttonUrl="/body"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img8}
                  imgAlt="EXPLORE SUN CARE"
                  caption=" Sun protection for everyone under the sun"
                  buttonText="EXPLORE SUN CARE"
                  buttonUrl="/sun"
                  imgStyles={{ width: 236 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default EucerinFaceImmersiveHydrationNightCreamPage;
